<!--提报新工单-->
<template>
  <div @click="closeMenu()">
    <el-form ref="replyform" :model="workorder" label-width="80px" size="mini">
      <el-row>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="msg('工单类型')">
            <el-select v-model="workorder.typeName" size="mini" clearable :placeholder="msg('请选择')"
                       :disabled="(workorder.status==2||workorder.status==3)||!canupdate" @change="workTypeChange">
              <el-option v-for="d in typelist"
                         :key="d" :label="d" :value="d"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="msg('工单细分')">
            <el-input v-model="workorder.typeDesc" :disabled="!canupdate" type="text" @blur="workTypeDescChange"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24">
          <el-form-item :label="msg('紧急程度')">
            <el-select v-model="workorder.level" :placeholder="msg('请选择')"
                       :disabled="(workorder.status==2||workorder.status==3)||!canupdate" @change="workLevelChange">
              <el-option v-for="d in getDicts('workOrderLevel')"
                         :key="d.value" :label="d.label" :value="d.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="msg('受理人')">
            <span>{{ workorder.handlerName }}</span>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="msg('处理进度')">
            <el-col :span="6">
              <el-input v-model="workorder.process" :disabled="!canprocess" type="number" @blur="doProcess" max="100" min="0"></el-input>
            </el-col>
            <el-col :span="6" style="text-align: right">
              <el-button type="primary" v-if="workorder.status==0||workorder.status==1" :disabled="!canupdate" icon="el-icon-close"
                         @click="doFinish" size="mini" style="margin-left:20px;">{{ msg('结束工单') }}
              </el-button>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="msg('工单主题')" :placeholder="msg('请输入')">
            <span>{{ workorder.title }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24">
          <el-form-item :label="msg('关联订单')" v-if="workorder.orderNos!=null&&workorder.orderNos.length>0">
            <span>{{ workorder.orderNos }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24">
          <el-form-item :label="msg('问题描述')" :placeholder="msg('请输入')">
            <span>{{ workorder.description }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div class="charting" style="height:100%; position:relative;">
          <!--聊天框-->
          <transition name="my-animate"
                      enter-active-class="animate__animated animate__fadeInRight animate__faster"
                      leave-active-class="animate__animated animate__fadeOutRight animate__faster">
            <el-col :xs="24" :sm="24" :class="['right-box']"
                    v-if="visiableCharting">
              <el-scrollbar :style="{height: '270px'}" ref="msgBoxScroll"
                            class="hidden-x">
                <div class="msg-box">
                  <div class="top-warning" @click="getMoreMessage">
                    {{ isMoreMessage ? msg("点击加载更多>>>") : msg("没有更多消息了..") }}
                  </div>
                  <template v-for="(m, idx) in messages">
                    <div v-if="m.fromId != user.id" class="msg msg-to" :key="idx"><!--别人发的-->
                      <div class="to-photo photo-wrap" :style="{overflow: 'hidden'}">
                        <div class="text-photo">{{ m.fromName }}</div>
                      </div>
                      <div class="msg-txt-wrap">
                        <div class="msg-fullname">{{ m.fromName }}</div>
                        <div class="msg-fullname">{{ m.created }}</div>
                        <div class="msg-txt" @click="clickMsg($event, m)" @contextmenu.prevent="openMenu($event,m)" v-if="m.type == 0">{{ m.message }}</div>
                        <div class="msg-img" @click="clickMsg($event, m)" @contextmenu.prevent="openMenu($event,m)" v-else-if="m.type == 1">
                          <img :src="imgUrl + m.message" alt="">
                        </div>
                        <div class="msg-img" @click="clickMsg($event, m)" @contextmenu.prevent="openMenu($event,m)" v-else-if="m.type == 2">
                          <span v-html="m.message"></span>
                        </div>
                      </div>
                    </div>
                    <div v-else class="msg msg-from" :key="idx"><!--我发的-->
                      <div class="from-photo photo-wrap" :style="{overflow: user.photo?'hidden': 'visible'}">
                        <img v-if="user.photo" :src="imgUrl+user.photo">
                        <div class="text-photo" v-else>{{ user.firstName }}</div>
                      </div>
                      <div class="msg-txt-wrap">
                        <div class="msg-fullname">{{ user.fullname }}</div>
                        <div class="msg-fullname">{{ m.created }}</div>
                        <div class="msg-txt" @click="clickMsg($event, m)" @contextmenu.prevent="openMenu($event,m)" v-if="m.type == 0">{{ m.message }}</div>
                        <div class="msg-img" @click="clickMsg($event, m)" @contextmenu.prevent="openMenu($event,m)" v-else-if="m.type == 1">
                          <img :src="imgUrl + m.message" alt="">
                        </div>
                        <div class="msg-img" @click="clickMsg($event, m)" @contextmenu.prevent="openMenu($event,m)" v-else-if="m.type == 2">
                          <span v-html="m.message"></span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </el-scrollbar>
              <div class="chart-tool" v-if="cansend">
                <el-link :underline="false" type="default" @click="uploadFile">
                  <i class="iconfont icon-seiyw41 font22"></i>
                </el-link>
                <el-link :underline="false" type="default" v-popover:emojiPopover @click="emojiShow">
                  <i class="iconfont icon-biaoqing font22"></i>
                </el-link>
                <el-select ref="atSend" v-model="workorder.sendToId"
                           v-if="canupdate&&workorder.userList.length>0"
                           :placeholder="msg('@指定用户')" :clearable="true" class="atBtn">
                  <el-option v-for="d in workorder.userList"
                             :key="d.id" :label="d.fullname" :value="d.id"></el-option>
                </el-select>
              </div>
              <div class="inputing-box">
                <div class="chatText" v-if="cansend" ref="editDiv" contenteditable="true" :placeholder="this.msg('按Enter+Ctrl发送')"
                     v-on:keyup="inputSendMsg" v-on:paste.capture.prevent="handlePaste"
                ></div>
                <el-button type="primary" v-if="cansend" @click="prepareSendMessage" size="small"><i
                    class="iconfont icon-xunzhao03"></i>{{ msg('发送') }}
                </el-button>
              </div>
            </el-col>
          </transition>
          <!--end 聊天框-->
        </div>
      </el-row>
    </el-form>
    <!--点击消息内容的弹窗-->
    <ul v-show="msgMenuVisible" ref="msgMenu" :style="{left:left+'px',top:top+'px'}" class="msgcontextmenu">
      <li @click="deleteMsg()">{{ msg('撤回消息') }}</li>
    </ul>

    <!--查看图片-->
    <el-image-viewer v-if="showViewer" :on-close="()=>{this.showViewer = false}" :url-list="[picUrl]" class="myImageViewer" />

    <el-popover
        ref="emojiPopover"
        placement="top"
        width="300"
        trigger="click"
        v-model="emojiPopoverVisiable">
      <el-scrollbar class="hidden-x" style="height:200px;">
        <ul class="icon-list">
          <li v-for="(emoji, idx) in emojis" :key="idx" @click="clickEmoji(emoji)">
            {{ emoji }}
          </li>
        </ul>
      </el-scrollbar>
    </el-popover>
    <div class="temp" style="display:none;" ref="temp">

    </div>
  </div>
</template>

<script>
import 'animate.css';
import workOrderReply from "@/views/sys/WorkOrderReply.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
    name: 'WorkOrderReply',
    mounted() {
      this.loadtypelist();
    },
    computed: {
      workOrderReply() {
        return workOrderReply
      }
    },
    components: {
      ElImageViewer: ElImageViewer,
    },
    data(){
        return{
          workorder: {},
          typelist: [],
          cansend: false,
          canprocess: false,
          canupdate: false,
          fromId: null, //发送用户ID
          fromName: null, //发送用户ID
          toId: null, //接收用户ID
          toName: null,
          user: null, //登录用户
          imgUrl: this.$kit.api.imgView,
          visiableCharting: true, //是否打开聊天窗口
          messages: [], //消息列表
          isMoreMessage: false,  //是否有更多的消息

          sendMsg: null,  //待发消息的输入框
          activeItem: {
            start: 0,
            length: 100,
            lastMsgId: 0
          },//聊天窗口页码信息
          msgTimer: null, //定时获取用户的最新消息
          duration: 60000,  //获取消息的间隔

          showViewer: false,  //查看图片
          picUrl: null,

          msgCtrlPopVisiable: false, //点击消息的下拉菜单
          currMsg: null,  //点击消息后，的消息内容
          msgCtrlPopEl: null,
          emojis:[
            "😀","😁","😂","😃","😄","😅","😆","😉","😊","😋","😎","😍","😘","😗",
            "😙","😚","☺","😇","😐","😑","😶","😏","😣","😥","😮","😯","😪","😫","😴",
            "😌","😛","😜","😝","😒","😓","😔","😕","😲","😷","😖","😞","😟","😤",
            "😢","😭","😦","😧","😨","😬","😰","😱","😳","😵","😡","😠","🌹","🍀",
            "🍎","💰","📱","🌙","🍁","🍂","🍃","🌷","💎","🔪","🔫","🏀","⚽","⚡",
            "👄","👍","🔥","👦","👧","👨","👩","👴","👵","👶","👱","👮","👲","👳",
            "👷","👸","💂","🎅","👰","👼","💆","💇","🙍","🙎","🙅","🙆","💁","🙋",
            "🙇","🙌","🙏","👤","👥","🚶","🏃","👯","💃","👫","👬","👭","💏","💑",
            "👪","💪","👈","👉","☝","👆","👇","✌","✋","👌","👍","👎","✊","👊","👋",
            "👏","👐","✍","💘","❤","💓","💔","💕","💖","💗","💙","💚","💛","💜",
            "💝","💞","💟","❣","💐","🌸","💮","🌹","🌺","🌻","🌼","🌷","🌱","🌿","🍀"
          ],
          emojiPopoverVisiable: false,

          remarkTxt: null, //备注，用于弹窗输入

          chatImgId:null,//粘贴的截图Id
          msgMenuVisible: false,
          top: 0,
          left: 0,
        }
    },
    // props: {
    //   workorder: Object,
    // },
    methods:{
      init(workorder){
        var _this = this;
        this.$axios.get(this.$kit.api.workorder.detail + "?id=" + workorder.id)
            .then((result) => {
              if(result.status){
                this.workorder = result.data;
                let u = JSON.parse(localStorage.getItem("user"));
                if (u.id == this.workorder.submitterId) {
                  this.fromId = this.workorder.submitterId;
                  this.fromName = this.workorder.submitterName;
                  this.toId = this.workorder.handlerId;
                  this.toName = this.workorder.handlerName;
                } else if (u.id == this.workorder.handlerId) {
                  this.fromId = this.workorder.handlerId;
                  this.fromName = this.workorder.handlerName;
                  this.toId = this.workorder.submitterId;
                  this.toName = this.workorder.submitterName;
                } else {
                  this.fromId = u.id;
                  this.fromName = u.fullname;
                  this.toId = this.workorder.handlerId;
                  this.toName = this.workorder.handlerName;
                }
                this.user = u;
                this.activeItem.orderId = this.workorder.id;
                this.activeItem.id = this.toId;
                this.activeItem.showName = this.toName;
                // this.getTotalTimer();
                this.messages = [];
                this.lastMsgId = 0;
                this.getMessages(false, ()=>{
                  if (_this.msgTimer != null) {
                    clearInterval(_this.msgTimer);
                    _this.msgTimer = null;
                  }
                  if (_this.cansend) {
                    _this.msgTimer = setInterval(() => {
                      _this.getMessages(false);
                    }, _this.duration);
                  }
                });
                if (this.workorder.status <= 1 && this.toId != null && this.fromId != null) {
                  this.cansend = true;
                  if (this.workorder.handlerId == u.id) {
                    this.canprocess = true;
                  }
                  if (this.workorder.handlerId == this.user.id || this.workorder.submitterId == this.user.id) {
                    this.canupdate = true;
                  }
                }
              } else {
                _this.$message.error(_this.msg(result.msg))
              }
            })
            .catch(function(err){
              console.log(err);
              _this.$message.error(_this.msg("加载失败"))
            });
      },
      //获取消息内容
      getMessages(prev, cb){ //prev: true: 表示将接受到的消息插入到前面
        let para = "?id="+this.toId
            + "&s=" + (this.activeItem['start'] || 0)
            + "&l=" + (this.activeItem['length'] || 100)
            + "&orderId=" + this.activeItem.orderId
            + (this.activeItem['lastMsgId'] !== 0?("&last="+this.activeItem['lastMsgId']):"");
        var _this = this;
        _this.$axios.get(this.$kit.api.workordermsg.listbyorder + para)
            .then((result) => {
              if(result.status){
                _this.isMoreMessage = result.data.length>0;
                if(prev){
                  for(let i=result.data.length-1; i>=0; i--){
                    _this.messages.unshift(result.data[i]);
                  }
                }else{
                  result.data.forEach(e => {
                    _this.messages.push(e);
                  })
                }
                if(result.data.length>0){
                  _this.activeItem["lastMsgId"] = result.data[result.data.length-1].id;
                }
                if(!prev){
                  _this.scrollerBottom();
                }
                if(cb){
                  cb();
                }
              }
            });
      },
      showView(picId){
        this.picUrl = this.imgUrl + picId;
        this.showViewer = true;
      },
      //监听消息框的输入时间
      inputSendMsg(e){
        if (event.ctrlKey && event.keyCode == 13) {
          this.prepareSendMessage(e);
        }
      },
      prepareSendMessage(e){
        let text = this.$refs.editDiv.innerText;

        this.sendMsg = text;
        if(!text){
          this.$message(this.msg('请输入要发送的内容'));
          return;
        }

        //添加到聊天框
        var originMsg = this.sendMsg;
        var messageContent = originMsg;
        if (this.workorder.sendToId) {
          messageContent = "@" + this.workorder.userList.find(a => a.id == this.workorder.sendToId).fullname + " " + messageContent;
        }
        let msg = {
          orderId: this.workorder.id,
          fromId: this.fromId,
          toId: this.workorder.sendToId || this.toId,
          message: messageContent,
          type: 0,
        }

        this.doSendMessage(msg, () => {
          this.sendMsg = originMsg;
        });
      },
      doSendMessage(message, failCb){
        this.messages.push(message);
        this.$refs.editDiv.innerText = "";

        //保存到服务器
        this.sendMsg = "";
        var _this = this;
        this.$axios.post(this.$kit.api.workordermsg.send, message)
            .then((result) => {
              if(!result.status){
                _this.$message(result.msg);
                _this.messages.splice(this.messages.length-1);
                if(failCb) failCb();
              }else{
                message['id'] = result.data;
                message['created'] = _this.$kit.fmtDateTime(new Date());
                _this.activeItem['lastMsgId'] = result.data;
                _this.scrollerBottom();
                _this.$set(_this.workorder, 'sendToId', null);
              }
            });
      },
      scrollerBottom() { //滚动到底部
        var _this = this;
        setTimeout(()=>{
          try {
            let msgScoller = _this.$refs.msgBoxScroll;
            msgScoller.wrap.scrollTop = msgScoller.wrap.scrollHeight
          } catch (e) {
            console.log(e);
          }
        }, 100);
      },
      getMoreMessage(){  //查看更多消息

        if(this.activeItem && this.isMoreMessage){
          let start = this.activeItem['start'];
          let length = this.activeItem['length'];

          this.activeItem['start'] = (start+1)*length;
          this.getMessages(true);
        }
      },
      openMenu(e, m) {
        e.stopPropagation();
        var sendTime = new Date(m.created).getTime();
        var endTime = new Date().getTime();
        if ((endTime - sendTime) / 1000 / 60 < 2) {
          var rec = e.target.getBoundingClientRect();
          let left = rec.x + e.target.offsetWidth / 2 - 60 || e.clientX - 50;
          let top = rec.y + e.target.offsetHeight || e.clientY + 30;
          var x = e.pageX;
          var y = e.pageY;

          this.top = top;
          this.left = left;

          this.currMsg = m;
          this.msgMenuVisible = true;
          var pop = this.$refs.msgMenu;
          document.body.appendChild(pop);
        }else{
          this.msgMenuVisible = false;
        }
      },
      closeMenu() {
        this.msgMenuVisible = false;
      },
      clickMsg(e, msg){ //点击消息
        if (msg.type == 1) {
          this.showView(msg.message)
        }
      },
      stopEvent(){ //隐藏好友pop
        if (this.msgMenuVisible) {
          this.msgMenuVisible = false;
        }
      },
      deleteMsg(){
        //删除消息
        var _this = this;
        var id = this.currMsg.id;
        this.$axios.get(this.$kit.api.workordermsg.deleteByUser + "?id=" + id)
            .then((result) => {
              _this.$message(result.msg);
              if (result.status) {
                let idx = this.messages.findIndex(m => m.id == id);
                if (idx > -1) {
                  _this.messages.splice(idx, 1);
                }
              }
            });
        this.msgMenuVisible = false;
      },
      showMsgPhoto(){ //查看消息类型的图片
        this.showView(this.currMsg.message);
        this.$refs.msgCtrlPop.style="display:none;";
      },
      clickEmoji(emoji){ //选中emoji
        if(this.$refs.editDiv.innerText){
          // this.sendMsg += emoji;
          this.$refs.editDiv.innerText += emoji;
        }else{
          // this.sendMsg = emoji;
          this.$refs.editDiv.innerText = emoji;
        }
        this.emojiPopoverVisiable = false;
      },
      uploadFile(){  //点击上传文件
        var _this = this;
        var inputFile = document.createElement("input");
        inputFile.type = "file";
        inputFile.style.display="none";
        //inputFile.accept = "image/gif, image/jpeg, image/png";
        inputFile.onchange =  function(e){
          e.preventDefault();

          let file = e.target.files[0];
          let size = file.size / 1024 / 1024;
          if(size>10){
            _this.$message(_this.msg('不能操作10M'));
            return;
          }
          let formData = new FormData();
          formData.append('file',file);
          _this.axios.post(_this.$kit.api.upload+"?auth=1&type=chat",
              formData,
              {headers:{'Content-Type':'multipart/form-data'}}
          ).then((result) => {
            if(result.status){

              let fileType = file.type.toLowerCase();
              let msgType = 2;
              if(fileType.indexOf('gif') > -1 || fileType.indexOf('jpg') > -1
                  || fileType.indexOf("jpeg") > -1 || fileType.indexOf("png") >-1) {
                msgType = 1;
              }

              let msgContent = null;
              if(msgType == 1){
                msgContent = result.data;
              }else{
                msgContent = "<a _target='blank' href='"+_this.$kit.api.imgView+result.data+"'>"+file.name+"</a>";
              }

              _this.doSendMessage({
                orderId: _this.workorder.id,
                fromId: _this.fromId,
                toId: _this.toId,
                type: msgType,
                message: msgContent
              });
            }else{
              _this.$message(_this.msg('发送失败'));
            }
          }).catch(function (error) {
            console.error(error);
          });
          _this.$refs.temp.removeChild(e.target);
        };

        this.$refs.temp.appendChild(inputFile);
        inputFile.click();

      },
      //标记为已读
      markRead(){
        this.$axios.get(this.$kit.api.workordermsg.markRead + "?orderId=" + this.workorder.id + "&toId=" + this.user.id)
            .then((result) => {
            });
      },
      //监听粘贴事件
      handlePaste(event){
        var isChrome = false;
        var _this = this;
        if ( event.clipboardData || event.originalEvent ) {
          //not for ie11  某些chrome版本使用的是event.originalEvent
          var clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
          if ( clipboardData.items ) {
            // for chrome
            var  items = clipboardData.items,
                len = items.length,
                blob = null;
            isChrome = true;

            if(items[0].type.indexOf("text") > -1){
              _this.$refs.editDiv.innerText += clipboardData.getData("text");
              return;
            }

            //阻止默认行为即不让剪贴板内容在div中显示出来
            event.preventDefault();
            //在items里找粘贴的image,据上面分析,需要循环
            for (var i = 0; i < len; i++) {
              if (items[i].type.indexOf("image") !== -1) {

                //getAsFile() 此方法只是living standard firefox ie11 并不支持
                blob = items[i].getAsFile();
              }
            }
            if ( blob !== null ) {
              var reader = new FileReader();
              reader.onload = function (event) {
                // event.target.result 即为图片的Base64编码字符串
                var base64_str = event.target.result
                //可以在这里写上传逻辑 直接将base64编码的字符串上传（可以尝试传入blob对象，看看后台程序能否解析）
                // console.log('base64_str',base64_str);
                _this.uploadImgFromPaste(base64_str);
              }
              reader.readAsDataURL(blob);
            }

          } else {
            //for firefox
            setTimeout(function () {
              //设置setTimeout的原因是为了保证图片先插入到div里，然后去获取值
              var imgList = document.querySelectorAll('#tar_box img'),
                  len = imgList.length,
                  src_str = '',
                  i;
              for ( i = 0; i < len; i ++ ) {
                if ( imgList[i].className !== 'my_img' ) {
                  //如果是截图那么src_str就是base64 如果是复制的其他网页图片那么src_str就是此图片在别人服务器的地址
                  src_str = imgList[i].src;
                }
              }
              // this.uploadImgFromPaste(src_str, 'paste', isChrome);
            }, 1);
          }
        } else {
          //for ie11
          setTimeout(function () {
            var imgList = document.querySelectorAll('#tar_box img'),
                len = imgList.length,
                src_str = '',
                i;
            for ( i = 0; i < len; i ++ ) {
              if ( imgList[i].className !== 'my_img' ) {
                src_str = imgList[i].src;
              }
            }
            // this.uploadImgFromPaste(src_str, 'paste', isChrome);
          }, 1);
        }
      },
      uploadImgFromPaste (base64Img) {
        var _this = this;
        this.$axios.post(this.$kit.api.uploadChatImg,{code:base64Img})
            .then((result) => {
              if(result.status){
                _this.chatImgId = result.data;
                //添加到聊天框
                let msg = {
                  orderId: _this.workorder.id,
                  fromId: _this.fromId,
                  toId: _this.toId,
                  message: result.data,
                  type: 1,
                }

                _this.doSendMessage(msg, () => {
                  _this.sendMsg = msg.message;
                });
              }else{
                this.message(result.msg || "上传失败");
              }
            });
      },
      doFinish(){
        var url = this.$kit.api.workorder.finish + "?id=" + this.workorder.id;
        var _this = this;
        this.$confirm('确定要结束工单吗?', this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(url)
              .then(function(result){
                if (result.status) {
                  _this.$message(_this.msg("操作成功"));
                  if (result.status) {
                    _this.$emit("close-dg", true);
                  }
                } else {
                  _this.$message(result.msg || _this.msg("操作失败"));
                }
              });
        }).catch((err) => {
          _this.msg('操作失败');
        });
      },
      onCLose(){
        if (this.msgTimer != null) {
          clearInterval(this.msgTimer);
        }
        this.msgMenuVisible = false;
      },
      doProcess(){
        if (this.workorder.id && this.workorder.process && this.workorder.process >= 0 && this.workorder.process <= 100) {
          var url = this.$kit.api.workorder.process;
          var _this = this;
          this.$axios.post(url, this.workorder)
              .then(function (result) {
                if (!result.status) {
                  _this.$message(_this.msg("保存进度失败"));
                }
              });
        }
      },
      workTypeChange(){
        var url = this.$kit.api.workorder.typeChange;
        var _this = this;
        this.$axios.post(url, this.workorder)
            .then(function (result) {
              if (!result.status) {
                _this.$message.error(_this.msg("修改工单类型失败：" + result.msg));
              }else{
                _this.$message.success(_this.msg("工单类型修改成功"));
                _this.getMessages(false);
              }
            });
      },
      workTypeDescChange(){
        var url = this.$kit.api.workorder.typeDescChange;
        var _this = this;
        this.$axios.post(url, this.workorder)
            .then(function (result) {
              if (!result.status) {
                _this.$message.error(_this.msg("修改工单细分失败：" + result.msg));
              }else{
                _this.$message.success(_this.msg("工单细分修改成功"));
                _this.getMessages(false);
              }
            });
      },
      workLevelChange(event){
        console.log(event)
        var url = this.$kit.api.workorder.levelChange;
        var _this = this;
        this.$axios.post(url, this.workorder)
            .then(function (result) {
              if (!result.status) {
                _this.$message.error(_this.msg("修改紧急程度失败：" + result.msg));
              }else{
                _this.$message.success(_this.msg("紧急程度修改成功"));
                _this.getMessages(false);
              }
            });
      },
      emojiShow(event){
        this.emojiPopoverVisiable = true;
      },
      loadtypelist(){
        var _this = this;
        var url = this.$kit.api.workorder.typelist;
        this.$axios.post(url)
            .then(function (result) {
              if (result.status) {
                if (result.data) {
                  _this.typelist = result.data;
                }
              } else {
                _this.$message.error(result.msg || _this.msg("创建失败"));
              }
            });
      },
    }
}
</script>

<style scoped lang="scss">
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}

.left-box{background: #E5E5E5; }
.my-info{
  padding: 10px;
  display:flex;
  align-items: center;
  flex-wrap:nowrap;
}

.my-photo-wrap, .fitem-photo, .to-photo, .from-photo{flex: 0 0 40px; width:45px; height:45px;
  border-radius: 50%; margin-right:10px; }
.my-photo-wrap img, .fitem-photo img, .to-photo img, .from-photo img{width:40px;}
.my-name{font-size:18px;}

.my-name, .fname, .chart-title{
  white-space: nowrap; text-overflow: ellipsis; overflow: hidden; word-break: break-all;
}

.search-wrap{padding:0px 10px 10px 10px; display:flex; align-items: center;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
}
.search-wrap >>> .el-input-group__append{padding: 0px 8px;}
.search-input{width: calc(100% - 30px); margin-right:5px;}
.add-friend i{font-size:29px !important;}

.fitem{display:flex; align-items: center; padding: 7px 10px; position:relative;}
.fitem:hover{background: #DBDBDB; cursor: pointer;}
.fitem.active{background: #C6C6C6;}
.badge{
  position: absolute;
  top: 3px;
  right: 10px;
  background: #F56C6C;
  color: white;
  border-radius: 11px;
  min-width: 18px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  padding:2px;
  cursor:pointer;
}

.fname{font-size:14px; color:#555555;}

.right-box{background: #F5F5F5;}
.chart-title-wrap{display:flex;  align-items: center; padding: 10px; box-sizing: content-box;
  border-bottom: 1px solid #E7E7E7; box-shadow: 0px 4px 4px rgba(0,0,0,0.1);
}

.msg-box{padding-top:10px;padding-bottom:20px;}
.msg{display:flex; }
.msg-txt{word-break: break-all; }
.msg-from{padding:5px 10px 5px 20%;}
.msg-to{padding: 5px 20% 5px 10px;}
.msg-txt, .msg-img{
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 6px;
  padding:6px; font-size:18px;
}

.msg-to .msg-txt, .msg-to .msg-img{background:white;  color: #555555;  box-shadow: 0px 0px 10px rgba(0,0,0,0.15);}
.msg-from .msg-txt, .msg-from .msg-img{background:#66B1FF;   color: white;  box-shadow: 0px 0px 10px rgba(0,0,255,0.15);}

.msg-fullname{font-size:12px; color:#8a8a8a; margin-bottom:3px;}
.msg-from .msg-fullname{text-align:right;}

.to-photo{margin-top:10px;}
.from-photo{margin-top:18px;}
.from-photo{margin-left:10px;}

.msg-from{flex-direction: row-reverse;}

.msg-img{max-width:200px; overflow:hidden; }
.msg-img img{max-width:200px;}

.chart-tool{
  box-shadow: 0 -4px 4px rgba(0,0,0, 0.1);
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  border-top: 1px solid rgba(0,0,0,0.1);
}

.chart-tool /deep/  .el-link{margin-left:15px;}
.inputing-box{display:flex; align-items: center; padding: 0px 10px; margin-top:10px;}
.inputing-box /deep/  .el-button{flex: 0 0 80px; margin-left:10px;}

.min-charting{
  position: absolute; top:0px; left:0px; z-index: 10;
}
.photo-wrap{
  background: #9BA1DD; border-radius: 50%; border: 2px solid rgba(255,255,255, 0.8);
  box-shadow: 0 0 10px rgba(0,0,0,0.3); min-width:45px;height:45px;  box-sizing: border-box;
}
.text-photo{display:inline-block; color: white; font-size:27px; font-weight:bold; text-align:center;
  width:45px;height:45px;  box-sizing: border-box;
}
.no-data{
  display: flex;
  align-items:center;
  justify-content: center;
  font-size: 22px;
  color: #aaa;
}

.user-ctrl{
  display:flex;
  flex-direction: column;
}
.ctrl-item{padding:8px 0px;}
.user-ctrl-pop{min-width:100px;
  position: absolute;
  z-index: 9999;
}
.top-warning{
  background: #f0f9eb;
  color: #67c23a;
  text-align: center;
  font-size: 13px;
  padding: 5px;
  position: relative;
  top: -9px;
  cursor:pointer;
}
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
  padding:8px; border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li{font-size:18px;}
.icon-list li:hover{background:rgba(239, 246, 235, 0.73);}
.chatText{
  height: 115px;
  width: 800px;
  border: 1px solid #ccc;
  overflow-y:auto;
  background-color: white!important;
}
.chatText:empty::before{
  color:lightgrey;
  content:attr(placeholder);
}
.myImageViewer{
  z-index: 9999 !important;
}
.atBtn {
  margin-left: 15px;
  width: 120px;
}
.msgcontextmenu{
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}
.msgcontextmenu li {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}
.msgcontextmenu li:hover {
  background: #eee;
}
</style>
