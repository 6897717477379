<!--转派工单-->
<template>
  <div>
    <el-form ref="changeForm" :model="changeDto" :rules="changeRules" label-width="80px" size="mini">
      <el-row>
        <el-col :xs="24" :sm="24">
          <el-form-item :label="msg('受理人')" :placeholder="msg('请选择')" prop="handlerId">
            <el-col :span="12">
              <el-select v-model="changeDto.handlerId" :placeholder="msg('请选择')" ref="handlerSelect">
                <el-option v-for="d in servicelist" :key="d.id" :label="d.fullname" :value="d.id"></el-option>
              </el-select>
              <el-link type="primary" icon="el-icon-circle-plus" @click="onHandlerSelect"></el-link>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <div style="text-align:center;">
        <el-button type="primary" size="mini" @click="submit" :fullscreen="isMinScreen"><i
            class="el-icon-check"></i><span>{{ msg('提交') }}</span></el-button>
        <el-button size="mini" v-on:click="$emit('close-dg')">{{ msg('取消') }}</el-button>
      </div>

    </el-form>
    <el-dialog :title="msg('选择用户')" :visible.sync="userSelectVisible" :fullscreen="isMinScreen"
               :close-on-click-modal="false" append-to-body>
      <user-select v-on:close-dg="closeUserSelectDialog" ref="UserSelect"/>
    </el-dialog>
  </div>
</template>

<script>

import UserSelect from "./UserSelect.vue";
export default {
    name: 'WorkOrderChange',
    mounted() {
      this.loadservicelist();
    },
    components:{
      UserSelect: UserSelect
    },
    data(){
        return{
          userSelectVisible: false,
          servicelist: [],
          changeRules: {
          },
        }
    },
    props: {
      changeDto: Object,
    },
    methods:{
        submit(){
          this.$refs['changeForm'].validate((valid) => {
            if (valid) {
              this.onSubmit();
            } else {
              return false;
            }
          });
        },
        onSubmit(){
          if(!this.changeDto.handlerId){
            this.$message(this.msg('请选择受理人'));
            return;
          }
          this.changeDto.handlerName =  this.$refs.handlerSelect.selected.label;
          var url = this.$kit.api.workorder.change;
          var _this = this;
          this.$axios.post(url, _this.changeDto)
              .then(function(result){
                  if (result.status) {
                    _this.$message(_this.msg("转派成功"));
                    if (result.status) {
                      _this.$emit("close-dg", true);
                    }
                  } else {
                    _this.$message(result.msg || _this.msg("转派失败"));
                  }
              });
        },
        onHandlerSelect(){
          this.userSelectVisible=true;
        },
        loadservicelist(){
          var _this=this;
          var url = this.$kit.api.workorder.servicelist;
          this.$axios.post(url)
              .then(function(result){
                if (result.status) {
                  if (result.status) {
                    _this.servicelist = result.data;
                  }
                } else {
                  _this.$message(result.msg || _this.msg("获取处理人列表失败"));
                }
              });
        },
        closeUserSelectDialog(user) {
          this.userSelectVisible = false;
          if (!this.servicelist.find(a => a.id == user.id)) {
            this.servicelist.push(user);
          }
          this.changeDto.handlerId = user.id;
        }
    },
    computed: {
      isMinScreen: {  //是否小屏幕，浏览器
        get() {
          var is = this.$store.state.isMinScreen;
          return is;
        }
      }
    }
}
</script>

<style scoped lang="scss">
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}


</style>
