<!-- 消息管理-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" type="danger" @click="doDelete()" v-if="hasPermission('message:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('内容')">
                        <el-input v-model="scope.query.content" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('发送者')">
                        <el-input v-model="scope.query.fromUser" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('接受者')" >
                        <el-input v-model="scope.query.toUser" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('状态')" >
                        <el-select v-model="scope.query.status" placeholder="全部" clearable>
                            <el-option v-for="item in dicts.readStatuses" :key="item.value" 
                                :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('类型')" >
                        <el-select v-model="scope.query.type" placeholder="全部" clearable> 
                            <el-option :label="msg('文本')" :value="0"></el-option>
                            <el-option :label="msg('图片')" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('日期')">
                        <el-date-picker  v-model="scope.query.Date" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            clearable
                            end-placeholder=""
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                resizable border
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="datas"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>
        <el-image-viewer v-if="showViewer" :on-close="()=>{this.showViewer = false}" :url-list="[imgUrl]" />
    </div>
    
</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
    name: 'Permission',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        ElImageViewer: ElImageViewer,
    },
    mounted(){
        this.buildColumn();
        this.list();
    },
    data(){
        return {
            query:{
                content:null,
                type: null,
                status: null,
                fromUser: null,
                toUser: null,
                Date: [],
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },

            showViewer: false,  //查看图片
            imgUrl: null,
            baseImgUrl: this.$kit.api.imgView,

            showQuery: false,
            treeConfig: {
                children: "children",
                line: true,
            },
            columns: [],
            datas: [],      //消息列表
           
            dgTitle: '',
            dgVisible: false,
            curr: null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        buildColumn(){
            let _this = this;
            this.columns = [
                { type: 'checkbox', width: 60 },
                {field: "id", title: this.msg("ID"), width: 80,
                    sortable:true,
                    slots: {

                    }
                },
                {field: "status", title: this.msg("状态"), minWidth:100,
                    sortable:true,
                    slots: {
                        default: ({ row }) => {
                            let status = this.dicts.readStatuses.find(d => d.value === row.status);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.status}</el-tag>
                            ];
                        },
                    }
                },
                {field: "fromName", title: this.msg("发送者"), sortable: true,
                    minWidth:120,
                    filters: [{data: {vals: [], sVal: ''}}],
                    filterRender: {name: 'FilterContent'}
                },
                {field: "toName", title: this.msg("接受者"), sortable: true,
                    minWidth:120,
                    filters: [{data: {vals: [], sVal: ''}}],
                    filterRender: {name: 'FilterContent'}
                },
                {field: "type", title: this.msg("类型"), minWidth:100,
                    sortable:true,
                    slots: {
                        default: ({ row }) => {
                            let status = this.dicts.messageTypes.find(d => d.value === row.type);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.type}</el-tag>
                            ];
                        },
                    }
                },
                {field: "message", title: this.msg("内容"),minWidth:180, showOverflow: true, sortable:true,
                    slots: {
                        default: ({ row }) => {
                            let type = row.type;
                            return [
                                type==1?<a href="#" onClick={() => _this.viewImg(row)}><img class="msg-img" src={_this.baseImgUrl+row.message}/></a>:<span>{row.message}</span>
                            ];
                        },
                    }
                },
                {field: "created", title: this.msg("发送时间"), minWidth:150, sortable: true,
                    filters: [{data:''}],
                    filterRender: {
                        name: 'ElDatePicker', 
                        props: {
                            type: 'daterange', 
                            rangeSeparator: '至', startPlaceholder: '开始日期', 
                            endPlaceholder: '结束日期'
                        }
                    }
                },
            ];
        },
        list(refresh){

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.message.list, params)
                .then((result) => {
                    if(result.status){
                        this.datas = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        doDelete(){
             var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            let ids = selectes.map(e => e.id).join(',');
            this.$confirm(this.fmt('确定要删除消息《%s》吗?', [ids]), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.get(_this.$kit.api.message.deletes+"?ids="+ids)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.list(true);
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        viewImg(row){
            if(row.message){
                this.imgUrl = this.baseImgUrl+row.message;
                this.showViewer = true;
            }
        }
    }
    
}
</script>

<style scoped lang="scss">
.msg-img{width:40px; max-height:40px;}
</style>
