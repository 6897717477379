<!--评价工单-->
<template>
<el-form ref="commentform" :model="commentDto" :rules="commentRules" label-width="80px" size="mini">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('评价')" prop="commentType">
              <el-select  v-model="commentDto.commentType" size="mini" clearable :placeholder="msg('请选择')">
                <el-option v-for="d in getDicts('workOrderComment')"
                           :key="d.value" :label="d.label" :value="d.value"></el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item :label="msg('问题是否解决')" prop="commentResult">
            <el-select v-model="commentDto.commentResult" size="mini" clearable :placeholder="msg('请选择')">
              <el-option v-for="d in getDicts('workOrderCommentResult')"
                         :key="d.value" :label="d.label" :value="d.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="12">
        <el-form-item :label="msg('意见建议')" :placeholder="msg('请输入')" prop="commentContent">
          <el-input type="textarea" v-model="commentDto.commentContent"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="submit" :fullscreen="isMinScreen"><i class="el-icon-check"></i><span>{{msg('提交')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

</el-form>
</template>

<script>
export default {
    name: 'WorkOrderAdd',
    data(){
        return{
          commentRules: {
            commentType: [
              {required: true, message: this.msg("请选择评价类型"), trigger: 'change'},
            ]
          },
        }
    },
    props: {
      commentDto: Object,
    },
    methods:{
        submit(){
          this.$refs['commentform'].validate((valid) => {
            if (valid) {
              this.onSubmit();
            } else {
              return false;
            }
          });
        },
        onSubmit(){
            var url = this.$kit.api.workorder.comment;
            var _this = this;
            this.$axios.post(url, _this.commentDto)
                .then(function(result){
                    if (result.status) {
                      _this.$message(_this.msg("评价成功"));
                      if (result.status) {
                        _this.$emit("close-dg", true);
                      }
                    } else {
                      _this.$message(result.msg || _this.msg("评价失败"));
                    }
                });
        }
    },
    computed: {
      isMinScreen: {  //是否小屏幕，浏览器
        get() {
          var is = this.$store.state.isMinScreen;
          return is;
        }
      },
      docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 220;
        }
      },
      routeTab: {
        get(){
          try{
            return this.$store.state.activeMenus[this.$route.path]
          }catch{
            return null;
          }
        }
      }
    }
}
</script>

<style scoped lang="scss">
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}


</style>
