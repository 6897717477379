<!-- 我的工单-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                  <el-link :underline="false" @click="list(true)">
                    <div class="wrap" >
                      <icon-svg icon-class="icon-huotaitongbu" font-size="27px;"/>
                      <span class="txt">{{msg('刷新')}}</span>
                    </div>
                  </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>
                        </div>                
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" @click="doExport()">
                      <div class="wrap">
                        <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                        <span class="txt">{{ msg('导出') }}</span>
                      </div>
                    </el-link>
                </div>
                <div class="xgroup">
                  <el-link :underline="false" @click="doComment()">
                    <div class="wrap">
                      <icon-svg icon-class="icon-xiaoxiguanli2" font-size="27px;"/>
                      <span class="txt">{{ msg('评价') }}</span>
                    </div>
                  </el-link>
                </div>
                <div class="xgroup">
                  <el-link :underline="false" @click="doChange()">
                    <div class="wrap">
                      <icon-svg icon-class="icon-daochu1" font-size="27px;"/>
                      <span class="txt">{{ msg('转派') }}</span>
                    </div>
                  </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" type="danger" @click="doDelete()" v-if="hasPermission('workorder:delete')">
                      <div class="wrap" >
                        <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                        <span class="txt">{{msg('删除')}}</span>
                      </div>
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" type="primary" @click="doAdd()" v-if="hasPermission('workorder:addNew')">
                      <div class="wrap" >
                        <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                        <span class="txt">{{msg('提交新工单')}}</span>
                      </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('提交时间')">
                      <el-date-picker  v-model="scope.query.SubmitTime" type="daterange"  :range-separator="msg('至')"
                                       value-format="yyyy-MM-dd"
                                       clearable
                                       end-placeholder="">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="msg('工单状态')" >
                      <el-select v-model="scope.query.status" placeholder="全部" clearable>
                        <el-option v-for="item in dicts.workOrderStatus" :key="item.value"
                                   :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('是否解决')" >
                      <el-select v-model="scope.query.commentResult" placeholder="全部" clearable>
                        <el-option v-for="item in dicts.workOrderCommentResult" :key="item.value"
                                   :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('工单类型')" >
                      <el-select v-model="scope.query.typeName" placeholder="全部" clearable>
                        <el-option v-for="item in typelist" :key="item"
                                   :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('工单细分')">
                      <el-input v-model="scope.query.typeDesc" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('工单主题')">
                        <el-input v-model="scope.query.title" clearable></el-input>
                    </el-form-item>
                  <el-form-item :label="msg('问题描述')">
                    <el-input v-model="scope.query.description" clearable></el-input>
                  </el-form-item>
                  <el-form-item :label="msg('紧急程度')" >
                    <el-select v-model="scope.query.level" placeholder="全部" clearable>
                      <el-option v-for="item in dicts.workOrderLevel" :key="item.value"
                                 :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="msg('报单人')">
                    <el-input v-model="scope.query.submitterName" clearable></el-input>
                  </el-form-item>
                  <el-form-item :label="msg('受理人')">
                    <el-input v-model="scope.query.handlerName" clearable></el-input>
                  </el-form-item>
                  <el-form-item :label="msg('最新跟进时间')">
                    <el-date-picker  v-model="scope.query.LastFollowupTime" type="daterange"  :range-separator="msg('至')"
                                     value-format="yyyy-MM-dd"
                                     clearable
                                     end-placeholder="">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item :label="msg('完结时间')">
                    <el-date-picker  v-model="scope.query.FinishTime" type="daterange"  :range-separator="msg('至')"
                                     value-format="yyyy-MM-dd"
                                     clearable
                                     end-placeholder="">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item :label="msg('评价')" >
                    <el-select v-model="scope.query.commentType" placeholder="全部" clearable>
                      <el-option v-for="item in dicts.workOrderComment" :key="item.value"
                                 :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="msg('意见建议')">
                    <el-input v-model="scope.query.commentContent" clearable></el-input>
                  </el-form-item>
                  <el-form-item :label="msg('关联订单')">
                    <el-input v-model="scope.query.orderNos" clearable></el-input>
                  </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div id="workd-order-table-wrap" class="table-wrap">
          <div class="nav-tag">
            <el-tag :effect="query.statusStr==null?'dark':'plain'" @click="doNav('-1')">全部({{ totalCount.statusall!=null ? totalCount.statusall : 0 }})</el-tag>
            <el-tag :effect="query.statusStr==='0,1'?'dark':'plain'" @click="doNav('0,1')">未完结({{ totalCount.status01!=null?totalCount.status01:0}})</el-tag>
            <el-tag :effect="query.statusStr==='0'?'dark':'plain'" @click="doNav('0')">待处理({{ totalCount.status0!=null?totalCount.status0:0}})</el-tag>
            <el-tag :effect="query.statusStr==='1'?'dark':'plain'" @click="doNav('1')">处理中({{ totalCount.status1!=null?totalCount.status1:0}})</el-tag>
            <el-tag :effect="query.statusStr==='2'?'dark':'plain'" @click="doNav('2')">已完结({{ totalCount.status2!=null?totalCount.status2:0}})</el-tag>
            <el-tag :effect="query.statusStr==='3'?'dark':'plain'" @click="doNav('3')">已评价({{ totalCount.status3!=null?totalCount.status3:0}})</el-tag>
          </div>
            <vxe-grid ref="dataTable" :height="docHeight"
                export-config
                resizable border
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="datas"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>

        <el-dialog :title="createTitle" :visible.sync="createVisible" :fullscreen="isMinScreen" :close-on-click-modal="false">
          <work-order-add :workorder="curr" v-on:close-dg="closeAddDialog" ref="WorkOrderAdd"/>
        </el-dialog>

        <el-dialog :title="replyTitle" :destroy-on-close="true" :visible.sync="replyVisible" :fullscreen="isMinScreen"
                   :close-on-click-modal="false" @opened="openedReply" @close="replyClose">
          <work-order-reply :workorder="replyOrder" v-on:close-dg="closeReplyDialog" ref="WorkOrderReply"/>
        </el-dialog>

        <el-dialog :title="commentTitle" :visible.sync="commentVisible" :fullscreen="isMinScreen" :close-on-click-modal="false">
          <work-order-comment :commentDto="commentDto" v-on:close-dg="closeCommentDialog" ref="WorkOrderComment"/>
        </el-dialog>

        <el-dialog :title="changeTitle" :visible.sync="changeVisible" :fullscreen="isMinScreen" :close-on-click-modal="false">
          <work-order-change :changeDto="changeDto" v-on:close-dg="closeChangeDialog" ref="WorkOrderChange"/>
        </el-dialog>
    </div>
</template>
<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import WorkOrderAdd from "./WorkOrderAdd.vue";
import WorkOrderReply from "./WorkOrderReply.vue";
import WorkOrderComment from "./WorkOrderComment.vue";
import WorkOrderChange from "./WorkOrderChange.vue";
export default {
    name: 'WorkOrderMy',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        WorkOrderAdd: WorkOrderAdd,
        WorkOrderReply: WorkOrderReply,
        WorkOrderComment: WorkOrderComment,
        WorkOrderChange: WorkOrderChange
    },
    mounted(){
        let u = JSON.parse(localStorage.getItem("user"));
        this.user = u;
        this.query.userId = u.id;
        this.buildColumn();
        this.list();

        this.loadtypelist();
    },
    data(){
        return {
            query:{
                userId: null,
                content:null,
                typeName: null,
                status: null,
                commentResult:null,
                fromUser: null,
                toUser: null,
                Date: [],
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },
            typelist:[],
            totalCount: [
            ],
            showViewer: false,  //查看图片
            imgUrl: null,
            baseImgUrl: this.$kit.api.imgView,

            showQuery: false,
            treeConfig: {
                children: "children",
                line: true,
            },
            columns: [],
            datas: [],      //消息列表
           
            createTitle: '',
            createVisible: false,
            replyTitle: '',
            replyVisible: false,
            curr: null,
            replyOrder:null,
            commentDto: null,
            commentTitle: '',
            commentVisible: false,
            changeTitle: '',
            changeVisible: false,
            changeDto: null,
            user: null
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        buildColumn(){
            let _this = this;
            this.columns = [
                { type: 'checkbox', width: 60 },
                {field: "submitTime", title: this.msg("提交时间"), minWidth:160,
                    sortable:true,
                },
                {
                  field: "lastFollowupTime", title: this.msg("跟进时间"), sortable: true,
                  minWidth: 160,
                },
                {field: "submitterName", title: this.msg("报单人"), sortable: true,
                    minWidth:120,
                    slots: {
                      default: ({ row }) => {
                        if (row.submitterId == this.user.id && row.submitterMsgNum > 0) {
                          return [
                            <span style="cursor:pointer">
                              <el-badge value={row.submitterMsgNum} class="msg-badge"  style="margin-right:10px">
                                <span class="el-icon-chat-dot-square"></span>
                              </el-badge>
                              <span>{row.submitterName}</span>
                            </span>
                          ]
                        }else{
                          return row.submitterName;
                        }
                      },
                    }
                },
                {field: "handlerName", title: this.msg("受理人"), minWidth:120,
                    sortable:true,
                    slots: {
                      default: ({ row }) => {
                        if (row.handlerId == this.user.id && row.handlerMsgNum > 0) {
                          return [
                            <span style="cursor:pointer">
                              <el-badge value={row.handlerMsgNum} class="msg-badge"  style="margin-right:10px">
                                <span class="el-icon-chat-dot-square"></span>
                              </el-badge>
                              <span>{row.handlerName}</span>
                            </span>
                          ]
                        }else{
                          return row.handlerName;
                        }
                      },
                    }
                },
                {
                  field: "levelText", title: this.msg("紧急程度"), sortable: true,
                  minWidth: 120,
                  filters: [{data: {vals: [], sVal: ''}}],
                  filterRender:{name: 'FilterContent'},
                },
                {field: "statusText", title: this.msg("工单状态"), sortable: true,
                    minWidth:120,
                    filters: [{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "typeName", title: this.msg("工单类型"),minWidth:120, showOverflow: true, sortable:true,
                    filters: [{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "typeDesc", title: this.msg("工单细分"),minWidth:120, showOverflow: true, sortable:true,
                  filters: [{data: {vals: [], sVal: ''}}],
                  filterRender:{name: 'FilterContent'},
                },
                {field: "title", title: this.msg("工单主题"), minWidth:150, sortable: true,
                  slots: {
                    default: ({row}) => {
                      if (row.myMsgNum > 0) {
                        return [
                          <span style="cursor:pointer">
                              <el-badge value={row.myMsgNum} class="msg-badge"  style="margin-right:10px">
                                <span class="el-icon-chat-dot-square"></span>
                              </el-badge>
                              <el-link type="primary">{row.title}</el-link>
                          </span>
                        ]
                      }else{
                        return [
                          <el-link icon="el-icon-chat-line-round" type="primary">{row.title}</el-link>
                        ]
                      }
                    },
                  }
                },
                {
                  field: "description", title: this.msg("问题描述"),
                  minWidth: 120,
                },
              {
                field: "process", title: this.msg("处理进度"), sortable: true,
                minWidth: 120,
                slots: {
                  default: ({ row }) => {
                    return row.process?(row.process+'%'):'';
                  },
                }
              },
              {
                field: "finishTime", title: this.msg("完结时间"), sortable: true,
                minWidth: 120,
              },
              {
                field: "commentTypeText", title: this.msg("评价"), sortable: true,
                minWidth: 120,
                filters: [{data: {vals: [], sVal: ''}}],
                filterRender:{name: 'FilterContent'},
              },
              {
                field: "commentContent", title: this.msg("意见建议"),
                minWidth: 120,
              },
              {
                field: "commentResultText", title: this.msg("解决程度"), sortable: true,
                minWidth: 120,
                filters: [{data: {vals: [], sVal: ''}}],
                filterRender:{name: 'FilterContent'},
              },
            ];
        },
        list(refresh){
            this.query.userId = this.user.id;
            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }
            this.listCount();

            var params = this.$kit.rebuildQuery(this.query);
            var _this = this;
            this.$axios.post(this.$kit.api.workorder.list, params)
                .then((result) => {
                    if(result.status){
                      _this.datas = result.data.list;
                        if(_this.datas){
                          _this.datas.forEach(a => {
                            a["statusText"] = _this.getDictLabel('workOrderStatus', a.status);
                            a["levelText"] = _this.getDictLabel('workOrderLevel', a.level);
                            a["commentTypeText"] = _this.getDictLabel('workOrderComment', a.commentType);
                            a["commentResultText"] = _this.getDictLabel('workOrderCommentResult', a.commentResult);
                          })
                        }
                      _this.query.pageTotal = result.data.total;
                    }
                });
        },
        listCount(){
          var params = this.$kit.rebuildQuery(this.query);
          this.$axios.post(this.$kit.api.workorder.listcount, params)
              .then((result) => {
                if (result.status) {
                  if (result.data && result.data.length > 0) {
                    this.totalCount = result.data[0];
                  } else {
                    this.totalCount ={
                      status0:0,
                      status01:0,
                      status1:0,
                      status2:0,
                      status3:0,
                      statusall:0
                    };
                  }
                }
              });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //点击行，选中行
        checkRow({row, $event, column}) {
            if (column.property == 'title') {
              if(!row.submitterId){
                this.$message(this.msg('未查询到工单的提单人信息'));
                return;
              }
              if(!row.handlerId){
                this.$message(this.msg('未查询到工单的处理人信息'));
                return;
              }
              this.replyOrder = row;
              this.replyTitle = this.msg("回复工单");
              this.replyVisible = true;

              if(row.myMsgNum>0){
                //已读
                this.$axios.get(this.$kit.api.workordermsg.markRead + "?orderId=" + row.id + "&toId=" + this.user.id)
                    .then((result) => {
                    });
              }
            } else if (column.property == 'submitterName') {
              if (row.submitterId == this.user.id) {
                this.replyOrder = row;
                this.replyTitle = this.msg("继续追问");
                this.replyVisible = true;

                //已读
                this.$axios.get(this.$kit.api.workordermsg.markRead + "?orderId=" + row.id + "&toId=" + this.user.id)
                    .then((result) => {
                    });
              }
            } else if (column.property == 'handlerName') {
              if (row.handlerId == this.user.id) {
                this.replyOrder = row;
                this.replyTitle = this.msg("回复工单");
                this.replyVisible = true;

                //已读
                this.$axios.get(this.$kit.api.workordermsg.markRead + "?orderId=" + row.id + "&toId=" + this.user.id)
                    .then((result) => {
                    });
              }
            } else {
              //如果点击checkbox 则不处理
              if ($event.target.className.indexOf("vxe-checkbox--icon") == -1) {
                var table = this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
              }
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        doAdd(){
            this.curr = {
              id:null,
              typeName:null,
              level:0,
              title:null,
              description:null,
            }

            this.createTitle = this.msg("新建工单");
            this.createVisible = true;
        },
        doComment(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
              this.$message(this.msg('请选中一条数据进行操作'));
              return;
            }
            let unvalidRow = selectes.filter(row => row.status != 2 || row.submitterId != this.user.id)
            if (unvalidRow.length > 0) {
              this.$message(this.msg('选择了无法评价的工单'));
              return;
            }
            let ids = selectes.map(e => e.id);
            this.commentDto = {
              ids: ids
            }

            this.commentTitle = this.msg("评价工单");
            this.commentVisible = true;
        },
        doDelete() {
          var selectes = this.$refs.dataTable.getCheckboxRecords();
          if(selectes.length==0){
            this.$message(this.msg('请选中一条数据进行操作'));
            return;
          }
          let ids = selectes.map(e => e.id);
          var _this = this;
          this.$confirm('确定要删除选中的工单吗?', this.msg('提示'), {
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
            type: 'warning'
          }).then(() => {
            _this.$axios.post(_this.$kit.api.workorder.delete,ids)
                .then((result) => {
                  if(result.status){
                    _this.list();
                    _this.$message(_this.msg('删除成功'));
                  }else{
                    _this.$message(result.msg);
                  }
                });
          }).catch((err) => {
            _this.msg('删除失败');
          });
        },
        closeAddDialog(type){  //添加弹窗关闭后的回调事件
          if(type){
            this.list(true);
          }
          this.createVisible = false;
        },
        closeReplyDialog(type){  //添加弹窗关闭后的回调事件
          // if(type) {
            this.list();
          // }
          this.replyVisible = false;
        },
        closeCommentDialog(type){  //添加弹窗关闭后的回调事件
          if(type) {
            this.list(true);
          }
          this.commentVisible = false;
        },
        closeChangeDialog(type){  //添加弹窗关闭后的回调事件
          if(type) {
            this.list(true);
          }
          this.changeVisible = false;
        },
        doExport(){
          this.$refs.dataTable.openExport({ isPrint: false })
        },
        doNav(statusStr){
          if (statusStr == '-1') {
            statusStr = null;
          }
          this.query.statusStr = statusStr;
          this.list(true);
        },
        openedReply() {
          var _this = this;
          this.$nextTick(() => {
            _this.$refs.WorkOrderReply.init(_this.replyOrder);
          });
        },
        replyClose(){
          this.$refs.WorkOrderReply.onCLose();
          this.list();
        },
        getDictLabel(type, value) {
          if (typeof (type) == 'undefined' || type == null || typeof (value) == 'undefined' || value == null) {
            return "";
          }
          var dictType = this.dicts[type];
          if (dictType == null) {
            return "";
          }
          var dictItem =  dictType.find(d => d.value === value)
          if (dictItem == null || dictItem.label == null) {
            return "";
          }
          return dictItem.label;
        },
        doChange(){
          var selectes = this.$refs.dataTable.getCheckboxRecords();
          if(selectes.length==0){
            this.$message(this.msg('请选中一条数据进行操作'));
            return;
          }
          let unvalidRow = selectes.filter(row => row.status > 1)
          if (unvalidRow.length > 0) {
            this.$message(this.msg('选择了无法转派的工单'));
            return;
          }
          let ids = selectes.map(e => e.id);
          this.changeDto = {
            ids: ids
          }

          this.changeTitle = this.msg("转派工单");
          this.changeVisible = true;
        },
        loadtypelist(){
          var _this = this;
          var url = this.$kit.api.workorder.typelist;
          this.$axios.post(url)
              .then(function (result) {
                if (result.status) {
                  if (result.data) {
                    _this.typelist = result.data;
                  }
                } else {
                  _this.$message.error(result.msg || _this.msg("创建失败"));
                }
              });
        },
    }
    
}
</script>

<style scoped lang="scss">
.msg-img{width:40px; max-height:40px;}
.nav-tag span{
  margin:5px 10px 5px 0px;
  cursor: pointer;
}

</style>
